<h2 mat-dialog-title
  >Run CV Pipeline {{ game.homeTeam }} - {{ game.awayTeam }}</h2
>
<mat-dialog-content>
  <div class="row">
    <div class="col">
      <mat-checkbox
        [(ngModel)]="liveMode"
        [disabled]="!hasLiveStream"
        matTooltip="Reads live stream and processes new game segments as FOINT is collected."
        >Live Mode</mat-checkbox
      >
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-form-field>
        <mat-select [(value)]="periods">
          <mat-option value="all">All Periods</mat-option>
          <mat-option value="1">Period 1</mat-option>
          <mat-option value="2">Period 2</mat-option>
          <mat-option value="3">Period 3</mat-option>
          <mat-option value="4">OT 1</mat-option>
          <mat-option value="5">OT 2</mat-option>
          <mat-option value="6">OT 3</mat-option>
          <mat-option value="7">OT 4</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-form-field>
        <mat-select [(value)]="phase" [disabled]="liveMode">
          <mat-option value="all">All Phases</mat-option>
          <mat-option value="face_off">Face-Off Detection</mat-option>
          <mat-option value="tracking">Player Tracking</mat-option>
          <mat-option value="identification">Player Identification</mat-option>
          <mat-option value="track_solve_post"
            >Update Player IDs with TOI</mat-option
          >
          <mat-option value="fieldmap_registration">Field Mapping</mat-option>
          <mat-option value="predict_toi">Time on Ice</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false" class="close-button"
    >Cancel</button
  >
  <button
    mat-raised-button
    color="warn"
    class="sync-button"
    (click)="runPipeline()"
    >Run CV Pipeline</button
  >
</mat-dialog-actions>

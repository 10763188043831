import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Game } from '../domain/game';

@Component({
  selector: 'app-cv-pipeline-dialog',
  templateUrl: './cv-pipeline-dialog.component.html',
  styleUrls: ['./cv-pipeline-dialog.component.css']
})
export class CvPipelineDialogComponent implements OnInit {
  game: Game;
  liveMode = false;
  hasLiveStream = false;
  periods = 'all';
  phase:
    | 'all'
    | 'tracking'
    | 'identification'
    | 'track_solve_post'
    | 'predict_toi' = 'all';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CvPipelineDialogComponent>
  ) {}

  ngOnInit() {
    this.game = this.data.game;
    this.hasLiveStream =
      this.game.videos.filter((v) => v.format === 'hls').length > 0;
  }

  runPipeline() {
    this.dialogRef.close({
      liveMode: this.liveMode,
      periods: this.periods,
      phase: this.phase
    });
  }
}

<div class="games-container container-fluid" style="min-height: 500px">
  <app-game-navigation [game]="game">
    <app-game-action-menu #actionMenu [game]="game"></app-game-action-menu>
  </app-game-navigation>

  <div class="card">
    <h5 class="card-header mt-0" (click)="showEssential = !showEssential"
      >Setup {{ selectedLeague?.shortName }} /
      {{ game.date | date : 'yyyy-MM-dd' }} {{ game.homeTeam }} -
      {{ game.awayTeam }}
    </h5>
    <div class="card-body" [style.display]="showEssential ? 'block' : 'none'">
      <div class="row">
        <div class="col-2">
          <label
            [matTooltip]="
              'Game date in your local timezone (' +
              usersLocalTimezoneShortName +
              ')'
            "
          >
            Date
            <span class="game-date-timezone"
              >({{ currentDate | date : 'z' }})</span
            >
          </label>
          <input
            name="date"
            class="form-control"
            [ngModel]="game.date | date : 'yyyy-MM-dd HH:mm'"
            (ngModelChange)="game.date = $event"
            placeholder="2017-09-30"
            type="datetime-local"
            required
            #date="ngModel"
            [class.is-invalid]="date.invalid"
          />
          <div class="invalid-feedback">Please provide a date.</div>
        </div>

        <div class="col-2">
          <label matTooltip="Game date in venue's local timezone">
            Venue Date
          </label>
          <input
            *ngIf="game.venueUTCOffset; else noTimeZoneOffset"
            name="venue_local_date"
            class="form-control"
            readonly
            [ngModel]="
              game.date | date : 'yyyy-MM-dd HH:mm' : game.venueUTCOffset
            "
          />
          <ng-template #noTimeZoneOffset>
            <input name="venue_timezone" class="form-control" readonly />
          </ng-template>
        </div>

        <div class="col-1">
          <label matTooltip="Venue's UTC timezone offset in hours">
            Venue Offset
          </label>
          <input
            name="venue_utc_offset"
            class="form-control"
            readonly
            [ngModel]="game.venueUTCOffset"
          />
        </div>

        <div class="col-2">
          <label matTooltip="Venue's local timezone">
            Venue's Local Timezone
          </label>
          <input
            *ngIf="game.venueTimezoneId; else noVenueLocalTimeZoneOffset"
            name="venue_local_timezone"
            class="form-control"
            readonly
            [ngModel]="game.venueTimezoneId"
          />
          <ng-template #noVenueLocalTimeZoneOffset>
            <input name="venue_local_timezone" class="form-control" readonly />
          </ng-template>
        </div>

        <div class="col-1">
          <label>Season</label>
          <select
            [(ngModel)]="game.season"
            name="season"
            class="form-control"
            required
            #season="ngModel"
            [class.is-invalid]="season.invalid"
            (ngModelChange)="onSeasonChange($event)"
          >
            <option *ngFor="let season of seasons" [value]="season">{{
              season
            }}</option>
          </select>
          <div class="invalid-feedback">Please provide a season.</div>
        </div>

        <div class="col-3">
          <label>Season Type</label>
          <select
            [(ngModel)]="game.seasonType"
            name="seasonType"
            class="form-control"
            required
            #seasonType="ngModel"
            [class.is-invalid]="seasonType.invalid"
          >
            <option
              *ngFor="let seasonType of seasonTypes"
              [value]="seasonType"
              >{{ seasonType }}</option
            >
          </select>
          <div class="invalid-feedback">Please provide a season type.</div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-2">
          <label>ID Type</label>
          <select
            [(ngModel)]="game.idType"
            name="idType"
            class="form-control"
            (ngModelChange)="onIdTypeChange($event)"
          >
            <option>None</option>
            <option *ngFor="let idType of idTypes" [value]="idType">{{
              idType
            }}</option>
          </select>
        </div>

        <div class="col-2" *ngIf="game.idType === 'SIHF'">
          <label>SIHF ID</label>

          <input
            class="form-control"
            [(ngModel)]="game.gameId"
            placeholder="SIHF ID"
            required
          />
        </div>

        <div class="col-3">
          <label>League</label>
          <select
            name="league"
            class="form-control"
            [(ngModel)]="game.league"
            (ngModelChange)="onLeagueChange($event)"
            required
            #league="ngModel"
            [class.is-invalid]="league.invalid"
            [disabled]="season.invalid"
          >
            <option [ngValue]="null">Select League</option>
            <option *ngFor="let league of leagues" [ngValue]="league.sihfId"
              >{{ league.shortName }} – {{ league.fullName }}</option
            >
          </select>
          <div class="invalid-feedback">Please provide a league.</div>
        </div>

        <div class="col-2">
          <app-team-input
            [placeholder]="'Home Team'"
            [idType]="game.idType"
            [shortName]="game.homeTeam"
            [teamId]="game.homeTeamId"
            [teams]="teams"
            (teamChange)="onHomeTeamChange($event)"
            [disabled]="teamChangeDisabled"
          ></app-team-input>
        </div>
        <div class="col-1" *ngIf="false">
          <label for="homeTeamId">ID</label>
          <input
            class="form-control"
            id="homeTeamId"
            name="homeTeamId"
            [(ngModel)]="game.homeTeamId"
            [disabled]="teamChangeDisabled"
            placeholder="Home Team ID"
            required
            #homeTeamId="ngModel"
            [class.is-invalid]="homeTeamId.invalid"
          />
          <div class="invalid-feedback">Please provide the home team ID.</div>
        </div>

        <div class="col-2">
          <app-team-input
            [placeholder]="'Away Team'"
            [idType]="game.idType"
            [shortName]="game.awayTeam"
            [teamId]="game.awayTeamId"
            [teams]="teams"
            (teamChange)="onAwayTeamChange($event)"
            [disabled]="teamChangeDisabled"
          ></app-team-input>
        </div>
        <div class="col-1" *ngIf="false">
          <label for="awayTeamId">ID</label>
          <input
            class="form-control"
            id="awayTeamId"
            name="awayTeamId"
            [(ngModel)]="game.awayTeamId"
            [disabled]="teamChangeDisabled"
            placeholder="Away Team ID"
            required
            #awayTeamId="ngModel"
            [class.is-invalid]="awayTeamId.invalid"
          />
          <div class="invalid-feedback">Please provide the away team ID.</div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-2">
          <label>Data Set</label>
          <select
            [(ngModel)]="game.dataSet"
            (ngModelChange)="onDataSetChange($event)"
            name="dataSet"
            class="form-control"
          >
            <option></option>
            <option *ngFor="let dataSet of dataSets" [value]="dataSet">{{
              dataSet
            }}</option>
          </select>
        </div>
        <div class="col-3">
          <label>Collector</label>
          <input
            name="event_collector_name"
            class="form-control"
            [(ngModel)]="game.eventCollectorName"
          />
        </div>
        <div class="col-3">
          <label>Tags</label>
          <div class="tags">
            <app-tag-form-field
              class="game-details"
              [tags]="game.tags"
              (tagChange)="tagsChange($event)"
            ></app-tag-form-field>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-2">
          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="is_live_collection"
              name="is_live_collection"
              [(ngModel)]="game.isLiveCollection"
            />
            <label
              class="form-check-label"
              for="is_live_collection"
              matTooltip="Optimizes user interface for live collection, adjusts required validations"
              >Live Collection</label
            >
          </div>
        </div>
        <div class="col-2">
          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="is_highlight_collection"
              name="is_highlight_collection"
              [(ngModel)]="game.isHighlightCollection"
            />
            <label
              class="form-check-label"
              for="is_highlight_collection"
              matTooltip="Enables highlight events and rating column"
              >Highlight collection</label
            >
          </div>
        </div>
        <div class="col-2">
          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="is_live_draft_events"
              name="is_live_draft_events"
              [(ngModel)]="game.isLiveDraftEvents"
            />
            <label
              class="form-check-label"
              for="is_live_draft_events"
              matTooltip="Optimizes FOINT/Shot event types for fast, uninterrupted creation of draft events, requires 2nd-pass"
              >Realtime Collection</label
            >
          </div>
        </div>
        <div
          class="col-2"
          *ngIf="
            !game.dataSet ||
            (game.dataSet !== 'LITE' && game.dataSet !== 'LITE_PLUS')
          "
        >
          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="auto_puck_possession_events"
              name="auto_puck_possession_events"
              [(ngModel)]="game.autoPuckPossessionEvents"
            />
            <label
              class="form-check-label"
              for="auto_puck_possession_events"
              matTooltip="Automatically adds neutral puck possession events directly after face-offs, shots and passes (chip, rim, incomplete)"
              >Automated Neutral Puck Possession</label
            >
          </div>
        </div>
        <div class="col-2">
          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="goal_clips"
              name="goal_clips"
              [(ngModel)]="game.goalClips"
            />
            <label
              class="form-check-label"
              for="goal_clips"
              matTooltip="Requires enriching goals with slow motion sequences"
              >Goal Clips</label
            >
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-2">
          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="is_sihf_live"
              name="is_sihf_live"
              [(ngModel)]="game.isSihfLive"
            />
            <label
              class="form-check-label"
              for="is_sihf_live"
              matTooltip="Exports data to SIHF Game Center in realtime"
              >SIHF Live Export</label
            >
          </div>
        </div>
        <div class="col-2">
          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="disable_updates"
              name="disable_updates"
              [(ngModel)]="game.disableUpdates"
            />
            <label
              class="form-check-label"
              for="disable_updates"
              matTooltip="Prevents game lineup from being overridden"
              >Disable Automated SIHF Updates</label
            >
          </div>
        </div>
        <div class="col-2">
          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="is_training_game"
              name="is_training_game"
              [(ngModel)]="game.isTrainingGame"
            />
            <label
              class="form-check-label"
              for="is_training_game"
              matTooltip="Data is not exported (Data Cockpit, SIHF, others)"
              >Training Game</label
            >
          </div>
        </div>
        <div class="col-2">
          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="lag_clock"
              name="lag_clock"
              [(ngModel)]="game.lagClock"
            />
            <label
              class="form-check-label"
              for="lag_clock"
              matTooltip="Shows a clock with difference to live in the video view"
              >Show Lag Clock</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card mt-3">
    <h5 class="card-header mt-0" (click)="showVideos = !showVideos"
      >Videos ({{ game.videos?.length }})</h5
    >
    <div class="card-body" [style.display]="showVideos ? 'block' : 'none'">
      <table>
        <tr>
          <td class="col-sm-1">Camera</td>
          <td class="col-sm-1">Variant</td>
          <td class="col-sm-1 col-offset">Offset</td>
          <td class="col-sm-1 col-format">Format</td>
          <td class="col-sm-4">Video URL</td>
          <td class="col-sm-3"></td>
        </tr>
        <tr
          *ngFor="
            let video of game.videos;
            index as index;
            first as first;
            last as last
          "
        >
          <td class="col-sm-1">
            <select
              class="form-control"
              [(ngModel)]="video.cameraAngle"
              [ngModelOptions]="{ standalone: true }"
            >
              <option [ngValue]="null">Select Camera Angle</option>
              <option *ngFor="let camera of cameras" [ngValue]="camera[1]">{{
                displayName(camera[0]) | titlecase
              }}</option>
            </select>
          </td>
          <td class="col-sm-1">
            <select
              class="form-control"
              [(ngModel)]="video.variant"
              [ngModelOptions]="{ standalone: true }"
            >
              <option [ngValue]="null">Select Variant</option>
              <option *ngFor="let variant of variants" [ngValue]="variant">{{
                variant
              }}</option>
            </select>
          </td>
          <td class="col-sm-1 col-offset">
            <input
              type="number"
              class="form-control"
              [(ngModel)]="video.offset"
              [ngModelOptions]="{ standalone: true }"
            />
          </td>
          <td class="col-sm-1 col-format">
            <select
              class="form-control"
              [(ngModel)]="video.format"
              [ngModelOptions]="{ standalone: true }"
            >
              <option [value]="null"></option>
              <option value="mp4">MP4</option>
              <option value="hls">HLS</option>
              <option value="dash">DASH</option>
              <option value="webm">WebM/MKV</option>
              <option value="webrtc">WebRTC</option>
            </select>
          </td>
          <td class="col-sm-4">
            <input
              type="text"
              id="videoUrl"
              name="videoUrl"
              class="form-control"
              placeholder="https://..."
              [(ngModel)]="video.url"
              (ngModelChange)="videoUrlChanged(video)"
              [ngModelOptions]="{ standalone: true }"
            />
          </td>
          <td class="col-sm-3" style="margin-top: 26px">
            <button
              class="btn btn-info"
              (click)="downloadVideo(video)"
              *ngIf="video.format !== 'webrtc'"
              [disabled]="!videoDownloadAvailable(game, video)"
            >
              Archive...</button
            >
            <select
              class="form-control"
              style="width: 150px; display: inline-block; margin-left: 8px"
              *ngIf="video.format !== 'webrtc'"
              [(ngModel)]="video.downloadStatus"
              [ngModelOptions]="{ standalone: true }"
            >
              <option ngValue="new">New</option>
              <option ngValue="in_progress">Download In Progress</option>
              <option ngValue="complete">Download Complete</option>
              <option ngValue="error">Download Failed</option>
            </select>

            <button
              class="remove-video-button"
              mat-icon-button
              type="button"
              (click)="removeVideo(video)"
              ><mat-icon>remove_circle_outline</mat-icon>
            </button>
            <button
              mat-icon-button
              class="add-video-button"
              (click)="addVideo()"
            >
              <mat-icon>add_circle_outline</mat-icon>
            </button>
          </td>
        </tr>
      </table>

      <button
        mat-icon-button
        class="add-video-button"
        *ngIf="game.videos.length === 0"
        (click)="addVideo()"
      >
        <mat-icon>add_circle_outline</mat-icon>
      </button>

      <div class="row mt-5">
        <div class="col-sm-12">
          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="time_synced"
              name="time_synced"
              [(ngModel)]="game.isCameraTimeSynchronized"
            />
            <label
              class="form-check-label"
              for="time_synced"
              matTooltip="Whether the timing of all cameras has been synced using offset adjustment"
              >Time Synced</label
            >
          </div>
        </div>

        <div class="col-sm-12 mt-3">
          <label>Connect Live Stream</label>
          <select
            class="form-control"
            style="width: 150px; display: inline-block; margin-left: 8px"
            [disabled]="isGameNew || mediaServers.length === 0"
            (change)="listLiveStreams($event.target.value)"
          >
            <option value=""></option>
            <option *ngFor="let server of mediaServers" [value]="server">{{
              server
            }}</option>
          </select>
          <button
            type="button"
            class="btn btn-success dropdown-toggle ml-3"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            [disabled]="!liveStreams?.length || isGameNew"
          >
            Add Live Streams <span class="caret"></span>
          </button>
          <ul class="dropdown-menu">
            <li (click)="addDefaultLiveStreams()"
              ><a [routerLink]="[]" style="padding: 0 8px"
                >NL Default Setup</a
              ></li
            >
            <li><hr /></li>
            <li
              *ngFor="let s of liveStreams"
              style="padding: 4px 8px"
              (click)="addLiveStream(s); $event.stopPropagation()"
            >
              <mat-icon
                *ngIf="s.type === 'liveStream'"
                title="live stream"
                [style.color]="s.status === 'broadcasting' ? 'green' : 'red'"
                >videocam</mat-icon
              >
              <mat-icon *ngIf="s.type === 'playlist'" title="playlist"
                >queue_music</mat-icon
              >
              <a [routerLink]="[]" style="vertical-align: top">{{
                s.name || s.streamId
              }}</a>
              <span class="stream-description" style="vertical-align: top">{{
                s.description
              }}</span>
              <span
                class="stream-type"
                style="vertical-align: top; color: grey"
              >
                {{ s.publishType }}</span
              >
              <span
                class="stream-start"
                style="vertical-align: top; color: grey"
                *ngIf="s.startTime"
              >
                {{ s.startTime | date : 'shortTime' }}</span
              >
              <mat-icon *ngIf="streamIsAdded(s.streamId)">done</mat-icon>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="card mt-3">
    <h5 class="card-header mt-0" (click)="showOfficials = !showOfficials"
      >Officials</h5
    >
    <div class="card-body" [style.display]="showOfficials ? 'block' : 'none'">
      <div class="row">
        <app-official-input
          *ngFor="let gameOfficialField of gameOfficialFields"
          class="col-12 col-lg-3"
          [officialLabel]="gameOfficialField.label"
          [official]="
            findOfficial(
              gameOfficialField.role,
              gameOfficialField.sequenceNumber
            )
          "
          [unknownOfficialInfo]="
            findGameOfficial(
              gameOfficialField.role,
              gameOfficialField.sequenceNumber
            )?.unknownOfficialInfo
          "
          (officialIdChange)="
            updateGameOfficial(
              $event,
              gameOfficialField.role,
              gameOfficialField.sequenceNumber
            )
          "
        ></app-official-input>
      </div>
    </div>
  </div>

  <div class="card mt-3">
    <h5 class="card-header mt-0" (click)="showLineup = !showLineup">Lineup</h5>
    <div class="card-body" [style.display]="showLineup ? 'block' : 'none'">
      <div class="form-check">
        <mat-slide-toggle [(ngModel)]="customLineup"
          >Custom Lineup</mat-slide-toggle
        >
      </div>

      <mat-tab-group mat-stretch-tabs>
        <mat-tab
          [label]="game?.homeTeam ? 'Lineup ' + game.homeTeam : 'Home Lineup'"
        >
          <ng-container
            *ngIf="
              customLineup;
              then unknownHomeTeamPlayers;
              else knownHomeTeamPlayers
            "
            >here is ignored</ng-container
          >
        </mat-tab>
        <mat-tab
          [label]="game?.homeTeam ? 'Lineup ' + game.awayTeam : 'Away Lineup'"
        >
          <ng-container
            *ngIf="
              customLineup;
              then unknownAwayTeamPlayers;
              else knownAwayTeamPlayers
            "
            >here is ignored</ng-container
          >
        </mat-tab>
      </mat-tab-group>

      <ng-template #knownHomeTeamPlayers>
        <div class="row">
          <h5 class="col-sm-12 col-lg-1">GK</h5>

          <app-all-players-input
            class="col-12 col-lg-3"
            position="goalkeeper"
            positionName="Goalkeeper"
            positionKey="homeGoalkeeper"
            [idType]="game.idType"
            [player]="game.homeGoalkeeper"
            [playerId]="game.homeGoalkeeperID"
            [players]="homeTeamPlayers"
            [teamName]="game.homeTeam"
            (playerChange)="
              handlePlayerSelect($event.player, $event.positionKey)
            "
          ></app-all-players-input>

          <app-all-players-input
            class="col-sm-12 col-lg-3"
            position="goalkeeper"
            positionName="Backup Goalkeeper"
            positionKey="homeBackupGoalkeeper"
            [idType]="game.idType"
            [player]="game.homeBackupGoalkeeper"
            [playerId]="game.homeBackupGoalkeeperID"
            [players]="homeTeamPlayers"
            [teamName]="game.homeTeam"
            (playerChange)="
              handlePlayerSelect($event.player, $event.positionKey)
            "
          ></app-all-players-input>
        </div>

        <ng-container *ngFor="let line of lines; let i = index">
          <div class="row">
            <h5 class="col-sm-12 col-lg-1">{{ i + 1 }}</h5>
            <app-all-players-input
              class="col-sm-12 col-lg"
              position="defender"
              positionName="Left Defender"
              positionKey="{{ 'home' + line + 'LeftDefence' }}"
              [idType]="game.idType"
              [players]="homeTeamPlayers"
              [teamName]="game.homeTeam"
              [player]="game['home' + line + 'LeftDefence']"
              [playerId]="game['home' + line + 'LeftDefenceID']"
              (playerChange)="
                handlePlayerSelect($event.player, $event.positionKey)
              "
            ></app-all-players-input>
            <app-all-players-input
              class="col-sm-12 col-lg"
              position="defender"
              positionName="Right Defender"
              positionKey="{{ 'home' + line + 'RightDefence' }}"
              [idType]="game.idType"
              [player]="game['home' + line + 'RightDefence']"
              [playerId]="game['home' + line + 'RightDefenceID']"
              [players]="homeTeamPlayers"
              [teamName]="game.homeTeam"
              (playerChange)="
                handlePlayerSelect($event.player, $event.positionKey)
              "
            ></app-all-players-input>

            <app-all-players-input
              class="col-sm-12 col-lg"
              position="forward"
              positionName="Left Forward"
              positionKey="{{ 'home' + line + 'LeftForward' }}"
              [idType]="game.idType"
              [player]="game['home' + line + 'LeftForward']"
              [playerId]="game['home' + line + 'LeftForwardID']"
              [players]="homeTeamPlayers"
              [teamName]="game.homeTeam"
              (playerChange)="
                handlePlayerSelect($event.player, $event.positionKey)
              "
            ></app-all-players-input>

            <app-all-players-input
              class="col-sm-12 col-lg"
              position="forward"
              positionName="Center"
              positionKey="{{ 'home' + line + 'Center' }}"
              [idType]="game.idType"
              [player]="game['home' + line + 'Center']"
              [playerId]="game['home' + line + 'CenterID']"
              [players]="homeTeamPlayers"
              [teamName]="game.homeTeam"
              (playerChange)="
                handlePlayerSelect($event.player, $event.positionKey)
              "
            ></app-all-players-input>

            <app-all-players-input
              class="col-sm-12 col-lg"
              position="forward"
              positionName="Right Forward"
              positionKey="{{ 'home' + line + 'RightForward' }}"
              [idType]="game.idType"
              [player]="game['home' + line + 'RightForward']"
              [playerId]="game['home' + line + 'RightForwardID']"
              [players]="homeTeamPlayers"
              [teamName]="game.homeTeam"
              (playerChange)="
                handlePlayerSelect($event.player, $event.positionKey)
              "
            ></app-all-players-input>
          </div>
        </ng-container>
      </ng-template>

      <ng-template #unknownHomeTeamPlayers>
        <h5>Goalkeepers</h5>
        <app-custom-player-input
          [game]="game"
          positionKey="homeGoalkeeper"
          positionName="Goalkeeper"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="homeBackupGoalkeeper"
          positionName="Backup Goalkeeper"
        ></app-custom-player-input>

        <h5>First line</h5>
        <app-custom-player-input
          [game]="game"
          positionKey="homeFirstLineLeftDefence"
          positionName="Left Defence"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="homeFirstLineRightDefence"
          positionName="Right Defence"
        ></app-custom-player-input>
        <br />
        <app-custom-player-input
          [game]="game"
          positionKey="homeFirstLineLeftForward"
          positionName="Left Forward"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="homeFirstLineCenter"
          positionName="Center"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="homeFirstLineRightForward"
          positionName="Right Forward"
        ></app-custom-player-input>

        <h5>Second line</h5>
        <app-custom-player-input
          [game]="game"
          positionKey="homeSecondLineLeftDefence"
          positionName="Left Defence"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="homeSecondLineRightDefence"
          positionName="Right Defence"
        ></app-custom-player-input>
        <br />
        <app-custom-player-input
          [game]="game"
          positionKey="homeSecondLineLeftForward"
          positionName="Left Forward"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="homeSecondLineCenter"
          positionName="Center"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="homeSecondLineRightForward"
          positionName="Right Forward"
        ></app-custom-player-input>

        <h5>Third line</h5>
        <app-custom-player-input
          [game]="game"
          positionKey="homeThirdLineLeftDefence"
          positionName="Left Defence"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="homeThirdLineRightDefence"
          positionName="Right Defence"
        ></app-custom-player-input>
        <br />
        <app-custom-player-input
          [game]="game"
          positionKey="homeThirdLineLeftForward"
          positionName="Left Forward"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="homeThirdLineCenter"
          positionName="Center"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="homeThirdLineRightForward"
          positionName="Right Forward"
        ></app-custom-player-input>

        <h5>Fourth line</h5>
        <app-custom-player-input
          [game]="game"
          positionKey="homeFourthLineLeftDefence"
          positionName="Left Defence"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="homeFourthLineRightDefence"
          positionName="Right Defence"
        ></app-custom-player-input>
        <br />
        <app-custom-player-input
          [game]="game"
          positionKey="homeFourthLineLeftForward"
          positionName="Left Forward"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="homeFourthLineCenter"
          positionName="Center"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="homeFourthLineRightForward"
          positionName="Right Forward"
        ></app-custom-player-input>

        <h5>Fifth/Extra line</h5>
        <app-custom-player-input
          [game]="game"
          positionKey="homeFifthLineLeftDefence"
          positionName="Left Defence"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="homeFifthLineRightDefence"
          positionName="Right Defence"
        ></app-custom-player-input>
        <br />
        <app-custom-player-input
          [game]="game"
          positionKey="homeFifthLineLeftForward"
          positionName="Left Forward"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="homeFifthLineCenter"
          positionName="Center"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="homeFifthLineRightForward"
          positionName="Right Forward"
        ></app-custom-player-input>
      </ng-template>

      <ng-template #knownAwayTeamPlayers>
        <div class="row">
          <h5 class="col-sm-12 col-lg-1">Goalkeepers</h5>

          <app-all-players-input
            class="col-12 col-lg-3"
            position="goalkeeper"
            positionName="Goalkeeper"
            positionKey="awayGoalkeeper"
            [idType]="game.idType"
            [player]="game.awayGoalkeeper"
            [playerId]="game.awayGoalkeeperID"
            [players]="awayTeamPlayers"
            [teamName]="game.awayTeam"
            (playerChange)="
              handlePlayerSelect($event.player, $event.positionKey)
            "
          ></app-all-players-input>

          <app-all-players-input
            class="col-sm-12 col-lg-3"
            position="goalkeeper"
            positionName="Backup Goalkeeper"
            positionKey="awayBackupGoalkeeper"
            [idType]="game.idType"
            [player]="game.awayBackupGoalkeeper"
            [playerId]="game.awayBackupGoalkeeperID"
            [players]="awayTeamPlayers"
            [teamName]="game.awayTeam"
            (playerChange)="
              handlePlayerSelect($event.player, $event.positionKey)
            "
          ></app-all-players-input>
        </div>

        <ng-container *ngFor="let line of lines">
          <div class="row">
            <h5 class="col-sm-12 col-lg-1">{{ line }}</h5>
            <app-all-players-input
              class="col-sm-12 col-lg"
              position="defender"
              positionName="Left Defender"
              positionKey="{{ 'away' + line + 'LeftDefence' }}"
              [idType]="game.idType"
              [player]="game['away' + line + 'LeftDefence']"
              [playerId]="game['away' + line + 'LeftDefenceID']"
              [players]="awayTeamPlayers"
              [teamName]="game.awayTeam"
              (playerChange)="
                handlePlayerSelect($event.player, $event.positionKey)
              "
            ></app-all-players-input>
            <app-all-players-input
              class="col-sm-12 col-lg"
              position="defender"
              positionName="Right Defender"
              positionKey="{{ 'away' + line + 'RightDefence' }}"
              [idType]="game.idType"
              [player]="game['away' + line + 'RightDefence']"
              [playerId]="game['away' + line + 'RightDefenceID']"
              [players]="awayTeamPlayers"
              [teamName]="game.awayTeam"
              (playerChange)="
                handlePlayerSelect($event.player, $event.positionKey)
              "
            ></app-all-players-input>

            <app-all-players-input
              class="col-sm-12 col-lg"
              position="forward"
              positionName="Left Forward"
              positionKey="{{ 'away' + line + 'LeftForward' }}"
              [idType]="game.idType"
              [player]="game['away' + line + 'LeftForward']"
              [playerId]="game['away' + line + 'LeftForwardID']"
              [players]="awayTeamPlayers"
              [teamName]="game.awayTeam"
              (playerChange)="
                handlePlayerSelect($event.player, $event.positionKey)
              "
            ></app-all-players-input>

            <app-all-players-input
              class="col-sm-12 col-lg"
              position="forward"
              positionName="Center"
              positionKey="{{ 'away' + line + 'Center' }}"
              [idType]="game.idType"
              [player]="game['away' + line + 'Center']"
              [playerId]="game['away' + line + 'CenterID']"
              [players]="awayTeamPlayers"
              [teamName]="game.awayTeam"
              (playerChange)="
                handlePlayerSelect($event.player, $event.positionKey)
              "
            ></app-all-players-input>

            <app-all-players-input
              class="col-sm-12 col-lg"
              position="forward"
              positionName="Right Forward"
              positionKey="{{ 'away' + line + 'RightForward' }}"
              [idType]="game.idType"
              [player]="game['away' + line + 'RightForward']"
              [playerId]="game['away' + line + 'RightForwardID']"
              [players]="awayTeamPlayers"
              [teamName]="game.awayTeam"
              (playerChange)="
                handlePlayerSelect($event.player, $event.positionKey)
              "
            ></app-all-players-input>
          </div>
        </ng-container>
      </ng-template>

      <ng-template #unknownAwayTeamPlayers>
        <h5>Goalkeepers</h5>
        <app-custom-player-input
          [game]="game"
          positionKey="awayGoalkeeper"
          positionName="Goalkeeper"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="awayBackupGoalkeeper"
          positionName="Backup Goalkeeper"
        ></app-custom-player-input>

        <h5>First line</h5>
        <app-custom-player-input
          [game]="game"
          positionKey="awayFirstLineLeftDefence"
          positionName="Left Defence"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="awayFirstLineRightDefence"
          positionName="Right Defence"
        ></app-custom-player-input>
        <br />
        <app-custom-player-input
          [game]="game"
          positionKey="awayFirstLineLeftForward"
          positionName="Left Forward"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="awayFirstLineCenter"
          positionName="Center"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="awayFirstLineRightForward"
          positionName="Right Forward"
        ></app-custom-player-input>

        <h5>Second line</h5>
        <app-custom-player-input
          [game]="game"
          positionKey="awaySecondLineLeftDefence"
          positionName="Left Defence"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="awaySecondLineRightDefence"
          positionName="Right Defence"
        ></app-custom-player-input>
        <br />
        <app-custom-player-input
          [game]="game"
          positionKey="awaySecondLineLeftForward"
          positionName="Left Forward"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="awaySecondLineCenter"
          positionName="Center"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="awaySecondLineRightForward"
          positionName="Right Forward"
        ></app-custom-player-input>

        <h5>Third line</h5>
        <app-custom-player-input
          [game]="game"
          positionKey="awayThirdLineLeftDefence"
          positionName="Left Defence"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="awayThirdLineRightDefence"
          positionName="Right Defence"
        ></app-custom-player-input>
        <br />
        <app-custom-player-input
          [game]="game"
          positionKey="awayThirdLineLeftForward"
          positionName="Left Forward"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="awayThirdLineCenter"
          positionName="Center"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="awayThirdLineRightForward"
          positionName="Right Forward"
        ></app-custom-player-input>

        <h5>Fourth line</h5>
        <app-custom-player-input
          [game]="game"
          positionKey="awayFourthLineLeftDefence"
          positionName="Left Defence"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="awayFourthLineRightDefence"
          positionName="Right Defence"
        ></app-custom-player-input>
        <br />
        <app-custom-player-input
          [game]="game"
          positionKey="awayFourthLineLeftForward"
          positionName="Left Forward"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="awayFourthLineCenter"
          positionName="Center"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="awayFourthLineRightForward"
          positionName="Right Forward"
        ></app-custom-player-input>

        <h5>Fifth/Extra line</h5>
        <app-custom-player-input
          [game]="game"
          positionKey="awayFifthLineLeftDefence"
          positionName="Left Defence"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="awayFifthLineRightDefence"
          positionName="Right Defence"
        ></app-custom-player-input>
        <br />
        <app-custom-player-input
          [game]="game"
          positionKey="awayFifthLineLeftForward"
          positionName="Left Forward"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="awayFifthLineCenter"
          positionName="Center"
        ></app-custom-player-input>
        <app-custom-player-input
          [game]="game"
          positionKey="awayFifthLineRightForward"
          positionName="Right Forward"
        ></app-custom-player-input>
      </ng-template>
    </div>
  </div>

  <div class="card mt-3">
    <h5 class="card-header mt-0" (click)="showAssistance = !showAssistance"
      >Computer Assistance</h5
    >
    <div class="card-body" [style.display]="showAssistance ? 'block' : 'none'">
      <div class="row" style="height: 48px">
        <div class="col-4">
          <div class="form-check">
            <label class="form-check-label" style="width: 150px"
              >Home Team starts</label
            >
            <label for="left-side">
              <input
                type="radio"
                id="left-side"
                name="left-side"
                [(ngModel)]="game.homeTeamStartPosition"
                value="left"
              />
              <span>Left Side</span>
            </label>
            <label for="right-side" style="margin-left: 16px">
              <input
                type="radio"
                id="right-side"
                name="right-side"
                [(ngModel)]="game.homeTeamStartPosition"
                value="right"
              />
              <span>Right Side</span>
            </label>
          </div>
        </div>
      </div>
      <div class="row" style="height: 48px">
        <div class="col-2">
          <div class="form-check">
            <label
              class="form-check-label"
              for="home_team_color"
              style="width: 100px"
              >Home Color</label
            >
            <input
              type="color"
              class="form-check-input"
              id="home_team_color"
              name="home_team_color"
              style="margin-top: 0"
              [(ngModel)]="game.homeTeamColor"
            />
          </div>
        </div>

        <div class="col-2">
          <div class="form-check">
            <label
              class="form-check-label"
              for="away_team_color"
              style="width: 100px"
              >Away Color</label
            >
            <input
              type="color"
              class="form-check-input"
              id="away_team_color"
              name="away_team_color"
              style="margin-top: 0"
              [(ngModel)]="game.awayTeamColor"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table style="width: 100%; margin-left: 16px">
            <thead>
              <tr>
                <th>Period</th>
                <th>Phases</th>
                <th>Status</th>
                <th>Created</th>
                <th>Started</th>
                <th>Stopped</th>
                <th>Duration</th>
                <th>Type</th>
                <th>Logs</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let p of game.cvPipeline ?? []"
                [class.cv-error]="isCVError(p)"
              >
                <td>{{ p.period }}</td>
                <td>{{ p.phase }}</td>
                <td>
                  <select
                    [(ngModel)]="p.lastStatus"
                    name="result"
                    class="form-control"
                  >
                    <option value=""></option>
                    <option value="SUBMITTED">SUBMITTED</option>
                    <option value="PENDING">PENDING</option>
                    <option value="RUNNABLE">RUNNABLE</option>
                    <option value="STARTING">STARTING</option>
                    <option value="RUNNING">RUNNING</option>
                    <option value="SUCCEEDED">SUCCEEDED</option>
                    <option value="FAILED">FAILED</option>
                  </select>
                </td>
                <td
                  >{{ p.createdAt | date : 'yyyy-MM-dd HH:mm:ss' }}
                  <mat-icon
                    *ngIf="p.createdBy"
                    [matTooltip]="p.createdBy"
                    style="vertical-align: bottom"
                    >person</mat-icon
                  ></td
                >
                <td
                  >{{ p.startedAt ?? p.start | date : 'yyyy-MM-dd HH:mm:ss' }}
                </td>
                <td
                  >{{ p.stoppedAt ?? p.stop | date : 'yyyy-MM-dd HH:mm:ss' }}
                </td>
                <td>{{
                  duration(p.startedAt ?? p.start, p.stoppedAt ?? p.stop)
                    | hhmmss
                }}</td>
                <td>
                  {{ cvTaskType(p) }}
                </td>
                <td>
                  <a [href]="logsUrl(p)" target="_blank"
                    ><mat-icon style="vertical-align: middle"
                      >link
                    </mat-icon></a
                  >
                  <a [href]="logsUrl(p)" target="_blank"> Logs</a>
                </td>
                <td>
                  <button mat-icon-button (click)="deleteCVPipelineRecord(p)"
                    ><mat-icon>delete</mat-icon></button
                  >
                </td>
              </tr>
            </tbody>
          </table>

          <div class="form-check" style="margin-top: 16px">
            <input
              type="checkbox"
              class="form-check-input"
              id="tracking"
              name="tracking"
              [(ngModel)]="game.playerTracks"
            />
            <label class="form-check-label" for="tracking">CV Processed</label>
          </div>
        </div>
      </div>

      <div class="row" style="margin-top: 32px">
        <div class="col-2">
          <div class="form-check">
            <label class="form-check-label">Top Scorers</label>
            <input
              class="form-control"
              *ngFor="let tid of game.topScorerIds ?? []"
              [ngModel]="tid"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card mt-3">
    <h5 class="card-header mt-0" (click)="showCollection = !showCollection"
      >Collection</h5
    >
    <div class="card-body" [style.display]="showCollection ? 'block' : 'none'">
      <div class="row mt-3">
        <div class="col-2">
          <label>Home Score</label>
          <input
            min="0"
            type="number"
            name="home_score"
            class="form-control"
            [(ngModel)]="game.homeGoals"
          />
        </div>
        <div class="col-2">
          <label>Away Score</label>
          <input
            min="0"
            type="number"
            name="away_score"
            class="form-control"
            [(ngModel)]="game.awayGoals"
          />
        </div>
        <div class="col-1">
          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="overtime"
              name="overtime"
              [(ngModel)]="game.overtime"
            />
            <label class="form-check-label" for="overtime">Overtime</label>
          </div>
        </div>
        <div class="col-1">
          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="shootout"
              name="shootout"
              [(ngModel)]="game.shootout"
            />
            <label class="form-check-label" for="shootout">Shootout</label>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-2" *ngIf="game.gameId">
          <label>SIHF Status</label>
          <select
            [(ngModel)]="game.sihfStatus"
            name="status"
            class="form-control"
          >
            <option *ngFor="let key of sihfGameStatusKeys" [ngValue]="key">{{
              sihfGameStatus[key]
            }}</option>
          </select>
        </div>
        <div class="col-2">
          <label>Game Progress</label>
          <select
            [(ngModel)]="game.progress"
            name="stateStatus"
            class="form-control"
          >
            <option [ngValue]="null"></option>
            <option
              *ngFor="let progressKey of gameProgressOptions"
              [ngValue]="progressKey"
              >{{ progressKey }}</option
            >
          </select>
        </div>
        <div class="col-2">
          <label>Collection Status</label>
          <select
            [(ngModel)]="game.status"
            (ngModelChange)="onGameStatusChange($event)"
            name="status"
            class="form-control"
          >
            <option *ngFor="let status of gameStatusOptions" [value]="status">{{
              status
            }}</option>
          </select>
        </div>
        <div class="col-2">
          <label>Aggregation Status</label>
          <select
            [(ngModel)]="game.aggregationStatus"
            name="aggregationStatus"
            class="form-control"
          >
            <option></option>
            <option
              *ngFor="let status of aggregationStatusOptions"
              [value]="status"
              >{{ status }}</option
            >
          </select>
        </div>
        <div class="col-2">
          <label>Live Stream Archiving Status</label>
          <select
            [ngModel]="game.finalization?.status"
            (change)="updateFinalizationStatus($event.target.value)"
            name="finalizationStatus"
            class="form-control"
          >
            <option></option>
            <option
              *ngFor="let status of finalizationStatusOptions"
              [value]="status"
              >{{ status }}</option
            >
          </select>
        </div>
        <div *ngIf="game.goalClips" class="col-2">
          <label>Goal Clips Status</label>
          <select
            [(ngModel)]="game.goalClipsStatus"
            name="goalClipsStatus"
            class="form-control"
          >
            <option></option>
            <option
              *ngFor="let status of goalClipsStatusOptions"
              [value]="status"
              >{{ status }}</option
            >
          </select>
        </div>
      </div>
    </div>
  </div>

  <button mat-button class="apply-button" (click)="apply()"> Apply </button>

  <button
    mat-raised-button
    color="primary"
    class="save-button ml-3"
    (click)="save()"
  >
    Save and Close
  </button>
</div>
